import { useContext, useState } from "react";
import { PostRequest } from "../api/requests/postRequest";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { useTranslation } from "react-i18next";
import moment from "moment";

const emailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

export default function Confirmation({ setErrorMessageKey, reservation }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { nextStep } = useContext(ReservationNavigationContext);

  const submitHandler = () => {
    if (reservation === undefined) return;
    else if (reservation.name === "") {
      setErrorMessageKey("Validation.EmptyName");
      return;
    } else if (!emailRegex.test(reservation.email)) {
      setErrorMessageKey("Validation.EmailNotValid");
      return;
    } else if (reservation.email.indexOf(" ") !== -1) {
      setErrorMessageKey("Validation.EmailHasWhiteSpace");
      return;
    } else if (reservation.phone.length < 8) {
      setErrorMessageKey("Validation.PhoneNotValid");
      return;
    } else if (!reservation.agreedToTerms) {
      setErrorMessageKey("Validation.TermsNotAgreed");
      return;
    }

    // 2023-06-18T10:00:00
    const start = `${moment.utc(reservation.startDate).format("YYYY-MM-DD")}T${
      reservation.startTime
    }`;

    const addReservation = async () => {
      setIsLoading(true);

      await PostRequest("/api/v1/reservations", { ...reservation, start })
        .then((data) => {
          if (data.paymentLink !== "" && data.paymentLink !== null)
            window.location.href = data.paymentLink;
          else nextStep();
        })
        .catch(() => {
          alert(t("Validation.ErrorAddingReservation"));
        });
      setIsLoading(false);
    };

    addReservation();
  };

  return (
    <div className="step-navigation-container">
      <button
        className="next-button"
        onClick={submitHandler}
        disabled={isLoading}
      >
        {isLoading ? t("UserDataStep.Submitting") : t("UserDataStep.Submit")}
      </button>
    </div>
  );
}
